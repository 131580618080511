const apps = [
  {
    path: '/authors/:id',
    name: 'author-view',
    component: () => import('@/views/apps/author/author-view/AuthorView.vue'),
    meta: {
      layout: 'content',
      resource: 'Author',
    },
  },
  {
    path: '/authors',
    name: 'authors',
    component: () => import('@/views/apps/author/author-list/Authors.vue'),
    meta: {
      layout: 'content',
      resource: 'Author',
    },
  },
  {
    path: '/ads_authors',
    name: 'ads_authors',
    component: () => import('@/views/apps/author/author-list/GoogleAdAuthors.vue'),
    meta: {
      layout: 'content',
      resource: 'Author',
    },
  },
  {
    path: '/adresses',
    name: 'address',
    component: () => import('@/views/apps/adresses/Adresses.vue'),
    meta: {
      layout: 'content',
      resource: 'Address',
    },
  },
  {
    path: '/libraries',
    name: 'libraries',
    component: () => import('@/views/apps/libraries/Libraries.vue'),
    meta: {
      layout: 'content',
      resource: 'Library',
    },
  },
  {
    path: '/cartographies',
    name: 'cartographies',
    component: () => import('@/views/apps/cartographies/Cartographies.vue'),
    meta: {
      layout: 'content',
      resource: 'Cartographie',
    },
  },
  {
    path: '/manuscripts/:id',
    name: 'manuscript-view',
    component: () => import('@/views/apps/author/author-view/AuthorView.vue'),
    meta: {
      layout: 'content',
      resource: 'Manuscript',
    },
  },
  {
    path: '/manuscripts',
    name: 'manuscripts',
    component: () => import('@/views/apps/manuscript/Manuscripts.vue'),
    meta: {
      layout: 'content',
      resource: 'Manuscript',
    },
  },
  {
    path: '/publications',
    name: 'publications',
    component: () => import('@/views/apps/publications/Publications.vue'),
    meta: {
      layout: 'content',
      resource: 'Publication',
    },
  },
  {
    path: '/orders',
    name: 'orders',
    component: () => import('@/views/apps/orders/Orders.vue'),
    meta: {
      layout: 'content',
      resource: 'Order',
    },
  },
  {
    path: '/quotations',
    name: 'quotations',
    component: () => import('@/views/apps/manuscript/Manuscripts.vue'),
    meta: {
      layout: 'content',
      resource: 'Cautation',
    },
  },
  {
    path: '/signatures',
    name: 'signatures',
    component: () => import('@/views/apps/publications/Signatures.vue'),
    meta: {
      layout: 'content',
      resource: 'Signature',
    },
  },
  {
    path: '/collecte',
    name: 'collecte',
    component: () => import('@/views/apps/publications/Collections.vue'),
    meta: {
      layout: 'content',
      resource: 'Collect',
    },
  },
  {
    path: '/assistance_texte',
    name: 'assistance_texte',
    component: () => import('@/views/apps/publications/AssistantText.vue'),
    meta: {
      layout: 'content',
      resource: 'Text',
    },
  },
  {
    path: '/assistance_couverture',
    name: 'assistance_couverture',
    component: () => import('@/views/apps/publications/AssistantCover.vue'),
    meta: {
      layout: 'content',
      resource: 'Cover',
    },
  },
  {
    path: '/supervision',
    name: 'supervision',
    component: () => import('@/views/apps/publications/Supervisor.vue'),
    meta: {
      layout: 'content',
      resource: 'Supervision',
    },
  },
  {
    path: '/assistance_publication',
    name: 'assistance_publication',
    component: () => import('@/views/apps/publications/AssistantPublication.vue'),
    meta: {
      layout: 'content',
      resource: 'Publication',
    },
  },
  {
    path: '/payments',
    name: 'payments',
    component: () => import('@/views/apps/publications/Payments.vue'),
    meta: {
      layout: 'content',
      resource: 'Paiment',
    },
  },
  {
    path: '/resiliations',
    name: 'resiliations',
    component: () => import('@/views/apps/publications/Resiliation.vue'),
    meta: {
      layout: 'content',
      resource: 'Resiliation',
    },
  },
  {
    path: '/report',
    name: 'report',
    component: () => import('@/views/apps/publications/Report.vue'),
    meta: {
      layout: 'content',
      resource: 'Report',
    },
  },
  {
    path: '/communications',
    name: 'communications',
    component: () => import('@/views/apps/communications/Communications.vue'),
    meta: {
      layout: 'content',
      resource: 'Communication',
    },
  },
]

export default apps
